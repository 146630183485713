<template>
  <div id="orderListBuyer" style="border: 0px solid #ddd; padding: 10px; background: white;" class="buyerodrlist">
    <div class="row">
      <div class="col-md-12 order-list-top">
        <div class="row">
          <div class="col-lg-6 col-md-6 col-sm-12">
            <h2 class="title">My Orders</h2>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12">
            <div class="row" style="margin-top: 15px; margin-bottom: 10px;">
              <div class="col-lg-9 col-md-9 col-sm-9 col-xs-9">
                <input
                  type="text"
                  style="font-size: 12px;"
                  v-model="searchKey"
                  class="form-control"
                  id="input-store"
                  placeholder="Search Order"
                  value
                  name="voucher"
                />
              </div>
              <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3" style="padding-left: 0px;">
                <button
                  type="button"
                  class="btn merchprof-sbtn"
                  style="min-height: 20px;"
                  @click="getOrders()"
                  :disabled="searchKey== ''"
                >Search</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="table-content" class="col-md-12 col-sm-12 buyerord--list">
        <div id="table-content" class="col-sm-12" style="padding-right: 0px; padding-left: 0px;">
          <table id="example" class="table table-striped">
            <thead>
              <tr>
                <th scope="col" class="head-table"></th>
                <th scope="col" class="head-table">Order</th>
                <th scope="col" class="head-table" style="text-align: center;">Date</th>
                <!-- <th scope="col" class="head-table" style="text-align: center;">Status</th> -->
                <!-- <th scope="col" class="head-table" style="text-align: center;">Shop Name</th> -->
                <th scope="col" class="head-table" style="text-align: center;">Amount</th>
                <th scope="col" class="head-table" style="text-align: left;">Payment Method</th>
                <th scope="col" class="head-table" style="text-align: center;">Action</th>
              </tr>
            </thead>
            <tbody>
              <div class="col-md-12" id="preview-area" v-if="blockLoading">
                <div class="row text-center">
                  <div class="sk-chase">
                    <div class="sk-chase-dot"></div>
                    <div class="sk-chase-dot"></div>
                    <div class="sk-chase-dot"></div>
                    <div class="sk-chase-dot"></div>
                    <div class="sk-chase-dot"></div>
                    <div class="sk-chase-dot"></div>
                  </div>
                </div>
              </div>
              <tr>
                <td
                  colspan="6"
                  class="text-center norec col-md-12"
                  v-if="blockLoading==false && tableItems.length==0"
                >No Records Found!</td>
              </tr>
              <tr v-for="(item, index) in tableItems" :key="index">
                <td style="vertical-align: middle;" class="text-center col-md-1">
                  <a>
                    <img
                      style="width:70px; height: 70px;"
                      :src="item.order_lines[0].simple_product_id.images[0].image"
                      class="img-thumbnail"
                    />
                  </a>
                </td>

                <td class="text-center" style="vertical-align: middle;">
                  <div class="row">
                    <div class="col-md-12 col-sm-12">
                      <span style="font-size: 12px;">
                        <b>{{ item.order_env_number }}</b>
                      </span>
                      <br />
                    </div>
                  </div>
                </td>

                <td
                  class="text-center col-md-2"
                  style="vertical-align: middle; font-weight: 700;"
                >{{ item.date }}</td>
                
                <!-- <td
                  class="text-center col-md-1"
                  style="vertical-align: middle; font-weight: 700;"
                >

                <span
                    class="badge"
                    style="border-radius: 4px; font-size: 10px; padding: 4px 6px;"
                    :style="
                      item.order_status === 0
                        ? 'background: rgb(225, 83, 55);'
                        : item.order_status === 1
                        ? 'background: rgb(254, 205, 54);'
                        : item.order_status === 10
                        ? 'background: rgb(254, 205, 54); color: white;'
                        : item.order_status === 20
                        ? 'background: rgb(0, 91, 170); color: white;'
                        : item.order_status === 30
                        ? 'background: rgb(163, 43, 185); color: white;'
                        : item.order_status === 40
                        ? 'background: rgb(77, 161, 48); color: white;'
                        : item.order_status === 41
                        ? 'background: #17c017; color: white;'
                        : item.order_status === 50
                        ? 'background: rgb(225, 83, 55); color: white;'
                        : item.order_status === 60
                        ? 'background: rgb(0, 91, 170); color: white;'
                        : 'background: rgb(0, 91, 170); color: white;'
                    "
                  >
                  {{
                    item.order_status === 0
                    ? "Cancelled"
                    : item.order_status === 1 && item.admin_approve === true && item.is_payment === true
                    ? "Pending"
                    : item.order_status === 1 && item.admin_approve === false && item.is_payment === false
                    ? "Payment Pending"
                    : item.order_status === 10
                    ? "Processing"
                    : item.order_status === 20
                    ? "Ready to ship"
                    : item.order_status === 30
                    ? "Shipped"
                    : item.order_status === 40
                    ? "Delivered"
                    : item.order_status === 41
                    ? "Delivery Confimed"
                    : item.order_status === 50
                    ? "Delivery Failed"
                    : item.order_status === 60
                    ? "Return"
                    : item.order_status === 80
                    ? "Dispute"
                    : item.order_status === 90
                    ? "dispute closed"
                    : "Error"
                  }}
                </span> 
               </td> -->
                
                <!-- <td class="text-center" style="vertical-align: middle;">
                  <div class="row">
                    <div class="col-md-12 col-sm-12">
                      <span style="font-size: 12px;" v-if="item.merchant_store && item.merchant_store.store_name !== undefined">
                        <b>{{ item.merchant_store.store_name }}</b>
                      </span>
                      <br />
                    </div>
                  </div>
                </td> -->

                <td
                  class="text-center col-md-2"
                  style="vertical-align: middle; font-weight: 700;"
                >{{"Rs:" + item.total_charge.toFixed(2)}}</td>
                <td
                  class="text-left col-md-2"
                  style="vertical-align: middle; font-weight: 700;"
                  v-if="item.payment_type === 1"
                >Supiri Pola Credit</td>
                <td
                  class="text-left col-md-2"
                  style="vertical-align: middle; font-weight: 700;"
                  v-if="item.payment_type === 2"
                >Cash On Delivery</td>
                <td
                  class="text-left col-md-2"
                  style="vertical-align: middle; font-weight: 700;"
                  v-if="item.payment_type === 3"
                >Bank Transfer</td>
                <td
                  class="text-left col-md-2"
                  style="vertical-align: middle; font-weight: 700;"
                  v-if="item.payment_type === 4"
                >Card Payment</td>
                <td style="vertical-align: middle; text-align: center;">
                  <button
                    type="button"
                    data-toggle="tooltip"
                    title
                    class="btn buyerprof-sbtn"
                    onclick
                    data-original-title="View"
                    @click="goToView(item)"
                  >View</button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="row text-right" style="margin-bottom: -20px; margin-top: -20px;">
        <div class="col-sm-12">
          <div>
            <b-pagination
              v-model="currentPage"
              ref="paginationCurrentPage"
              :per-page="pageSize"
              :total-rows="rowcount"
              align="right"
            ></b-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import mixinOrder from "@/mixins/buyer/APIOrder";
import mixinProduct from "@/mixins/buyer/APIProduct";
export default {
  mixins: [mixinOrder, mixinProduct],
  components: {},
  data() {
    return {
      breadcrumb: [
        {
          text: "Order List",
          active: true
        }
      ],
      selectedArray: [],
      pageSize: 5,
      currentPage: 1,
      conditionCp: 1,
      rowcount: 0,
      tableItems: [],
      blockLoading: true,
      searchKey: ""
    };
  },
  watch: {
    currentPage: function(val) {
      if (val) {
        this.pagination(this.pageSize, val);
        // this.paginationStyleFunction(
        //   this.$refs.paginationCurrentPage.$refs.ul.children
        // );
      }
    },
    searchKey: function(val) {
      if (val === "") {
        this.getOrders();
      }
    }
  },
  created() {
    this.handleBreadcrumb();
    this.getOrders();
    this.checkCardPaymentSuccess();
  },
  // mounted() {
  //   this.paginationStyleFunction(
  //     this.$refs.paginationCurrentPage.$refs.ul.children
  //   );
  // },
  methods: {
    paginationStyleFunction(array) {
      if (array === "") {
        array = this.$refs.paginationCurrentPage.$refs.ul.children;
      }
      if (this.conditionCp !== this.currentPage) {
        for (let x = 0; x < array.length; x++) {
          if (array[x].innerText === this.conditionCp.toString()) {
            array[x].children[0].style.border = "";
            array[x].children[0].style.background = "";
            array[x].children[0].style.color = "black";
          }
        }
        this.conditionCp = this.currentPage;
      }
      for (let x = 0; x < array.length; x++) {
        if (array[x].innerText === this.currentPage.toString()) {
          array[x].children[0].style.border = "2px solid #337ab7";
          array[x].children[0].style.background = "#337ab7";
          array[x].children[0].style.color = "black";
        }
      }
    },
    handleBreadcrumb: function() {
      this.$store.commit("setBreadcrumbSwitchState", {
        action: null,
        payload: this.breadcrumb
      });
    },
    getOrders: async function() {
      try {
        this.blockLoading = true;
        let params = "?page="+ this.currentPage;
        params += "&perpage=" + this.pageSize;
        params += "&order_no=" + this.searchKey;
        // let responce = await this.getAllOrdersForAUser();
        let responce = await this.getAllOrdersForAUserByMerchat(params);
        let res1 = await this.getOrderListCount(params)
        this.rowcount = res1.count;
        this.tableItems = responce;
        this.blockLoading = false;
      } catch (error) {
        throw error;
      }
    },
    pagination: function(pagesize, currentPage) {
      let pagesizeint = parseInt(this.pageSize);
      this.currentPage = currentPage;
      this.getOrders();
    },
    goToView: function(item) {
      this.$router.push({
        name: "Buyer Order Configure",
        params: { id: item.order_env_number }
      });
    },
    checkCardPaymentSuccess: function() {
      var order_env_number = getUrlParameterByName("req_reference_number");
      // var version = getUrlParameterByName("sessionVersion");
      if (getUrlParameterByName("decision")) {
        var success_indicator = getUrlParameterByName("decision")=="ACCEPT"?1:0;
      }
      if (success_indicator === 1) {
        // success
        var obj = {
          order_env_number: order_env_number,
          status: true,
          // version: version,
          success_indicator: success_indicator
        };
        var responce = this.saveCardPaymentSuccess(obj);
        this.getOrders();
        this.$swal.fire({
          position: "center",
          type: "success",
          title: "ORDER PLACED",
          showConfirmButton: false,
          timer: 1500
        });
        this.$router.replace('/buyer-orders')
      } else if (
        success_indicator === 0
      ) {
        var obj = { order_env_number: order_env_number, status: false };
        var responce = this.saveCardPaymentSuccess(obj);
        this.getOrders();
        this.$swal.fire({
          position: "center",
          type: "error",
          title: "Payment Not Completed",
          showConfirmButton: false,
          timer: 1500
        });
        this.$router.replace('/buyer-orders')
      } else{
        order_env_number = "";
      }
    }
  }
};
</script>
<style>
#orderListBuyer {
  position: relative;
}
#orderListBuyer .vdp-datepicker input {
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
  height: 34px;
  padding: 6px 12px;
}
#orderListBuyer .sk-chase {
  width: 40px;
  height: 40px;
  position: relative;
  animation: sk-chase 2.5s infinite linear both;
  z-index: 5;
}

.pagination li button {
  padding: 1px 12px;
  min-height: 1.42857143;
}
.pagination li.active > button {
  border: 2px solid #5779ae;
  background: #5779ae none repeat scroll 0% 0%;
  color: white !important;
}
.pagination > li > span {
  line-height: auto;
}
.pagination > li > span {
  float: none;
}

#orderListBuyer .sk-chase-dot {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  animation: sk-chase-dot 2s infinite ease-in-out both;
}
#orderListBuyer .sk-chase-dot:before {
  content: "";
  display: block;
  width: 25%;
  height: 25%;
  background-color:#5779ae;
  border-radius: 100%;
  animation: sk-chase-dot-before 2s infinite ease-in-out both;
}
#orderListBuyer .sk-chase-dot:nth-child(1) {
  animation-delay: -1.1s;
}
#orderListBuyer .sk-chase-dot:nth-child(2) {
  animation-delay: -1s;
}
#orderListBuyer .sk-chase-dot:nth-child(3) {
  animation-delay: -0.9s;
}
#orderListBuyer .sk-chase-dot:nth-child(4) {
  animation-delay: -0.8s;
}
#orderListBuyer .sk-chase-dot:nth-child(5) {
  animation-delay: -0.7s;
}
#orderListBuyer .sk-chase-dot:nth-child(6) {
  animation-delay: -0.6s;
}
#orderListBuyer .sk-chase-dot:nth-child(1):before {
  animation-delay: -1.1s;
}
#orderListBuyer .sk-chase-dot:nth-child(2):before {
  animation-delay: -1s;
}
#orderListBuyer .sk-chase-dot:nth-child(3):before {
  animation-delay: -0.9s;
}
#orderListBuyer .sk-chase-dot:nth-child(4):before {
  animation-delay: -0.8s;
}
#orderListBuyer .sk-chase-dot:nth-child(5):before {
  animation-delay: -0.7s;
}
#orderListBuyer .sk-chase-dot:nth-child(6):before {
  animation-delay: -0.6s;
}
#orderListBuyer .order-list-top {
  padding-top: 10px;
}
#orderListBuyer .search-prd-block {
  text-align: right;
}
#orderListBuyer .search-prd-block input[type="text"] {
  display: inline-block;
  width: 50%;
}
#orderListBuyer .filter-block select {
  display: inline-block;
  width: 75%;
  height: 35px !important;
}
#orderListBuyer .filter-block button {
  float: right;
}
#orderListBuyer .from-to-block {
  padding-top: 15px;
}
#orderListBuyer .pagination {
  margin-right: 20px;
}
.buyerodrlist .buyerprof-sbtn {
  background: #5779ae;
  color: #ffffff;
  border: none;
}
.buyerodrlist .buyerprof-sbtn:hover {
  color: #000000;
}
@keyframes sk-chase {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes sk-chase-dot {
  80%,
  100% {
    transform: rotate(360deg);
  }
}
@keyframes sk-chase-dot-before {
  50% {
    transform: scale(0.4);
  }
  100%,
  0% {
    transform: scale(1);
  }
}
#preview-area {
  width: 60px;
  height: 60px;
  position: absolute;
  /* top: 0; */
  left: 0;
  /* bottom: 60px; */
  right: 0;
  margin: auto;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
@media screen and (max-width: 1348px) {
  table {
    width: 960px !important;
  }
  .img-thumbnail {
    max-width: 80px;
  }
}
@media screen and (max-width: 1275px) {
  .buyerord--list{
    max-width: 1000px;
    overflow-x: scroll;
  }
}
@media screen and (max-width: 1200px) {
  #orderListBuyer .search-prd-block input[type="text"] {
    width: 100%;
  }
  #orderListBuyer .filter-block select {
    width: 90%;
  }
}
@media screen and (max-width: 725px) {
  #orderListBuyer .filter-block select {
    width: 80%;
  }
}
@media screen and (max-width: 625px) {
  #orderListBuyer {
    border: none !important;
  }
}
@media screen and (max-width: 560px) {
  #orderListBuyer .from-block,
  .to-block {
    width: 100%;
  }
  #orderListBuyer .from-to-block .dp-adjust {
    width: 100%;
  }
}
@media screen and (max-width: 375px) {
  #orderListBuyer .filter-block select {
    width: 70%;
  }
}
@media (max-width: 425px) {
  .nav-tabs > li > a {
    margin-right: 2px;
    line-height: 1.42857143;
    border: 1px solid transparent;
    border-radius: 4px 4px 0 0;
    margin-bottom: 6px;
    border: 1px solid #555;
    border-radius: 2px;
  }
  .nav-tabs > li {
    float: left;
    margin-bottom: -1px;
    margin-right: 4px;
  }
  .norec {
    text-align: left;
  }
}
</style>